<template>
  <div class="alldata">
    <div class="topUsers">
      <div>
        <el-input v-model="conlistParams.keywords" placeholder="请输入名称关键字"></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="datalist"
      style="width: 100%"
    >
      <el-table-column prop="name" label="企业名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="tel" label="联系方式" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="register_time" label="注册时间" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="update_time" label="流入时间" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="状态">
        <template slot-scope="scope">
          <span v-if="!scope.row.status" style="color: #FF6F7A;">未沟通</span>
          <span v-if="scope.row.status == 1" style="color: #0F38FF;">沟通中</span>
          <!-- <span v-if="scope.row.status == 2">添加到意向</span>
          <span v-if="scope.row.status == 3">添加到合作</span>-->
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.status"
            class="edit"
            size="mini"
            @click="goConnect(scope.row.id)"
          >去沟通</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" v-if="datalist.total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="datalistParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="datalistParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="datalist.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      conlistParams: {
        page: 1,
        limit: 10,
        keywords: "",
        status: 0,
      },
      datalist: [],
    };
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/company/custom_pool', { params: { ...this.conlistParams } }).then((res) => {
        this.datalist = res.data.data
      })
    },
    handleSizeChange (val) {
      this.getList(this.conlistParams.limit = val)
    },
    handleCurrentChange (val) {
      this.getList(this.conlistParams.page = val)
    },
    handleSearch () {
      this.getList()
    },
    goConnect (id) {
      this.axios.post('/api/company/connect_custom', { id: id }).then((res) => {
        this.$message.success('已添加至我的沟通')
        this.getList()
      })
    },
  },
};
</script>

<style scoped>
.topUsers {
  margin-top: 22px;
}
</style>