<template>
  <div>
    <Header title="公共池" index="首页" titleOne="客户管理" beforeTitle="公共池" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="公共用户" name="first">
          <Alldata ref="alldata" />
        </el-tab-pane>
        <el-tab-pane label="我的沟通" name="second">
          <Mydata ref="mydata" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Alldata from "../../components/wxData/alldata";
import Mydata from "../../components/wxData/mydata";
export default {
  data () {
    return {
      activeName: "first",
    };
  },
  components: {
    Alldata,
    Mydata,
  },
  methods: {
    handleClick () {
      if (this.activeName == 'first') {
        this.$refs.alldata.getList()
      } else if (this.activeName == 'second') {
        this.$refs.mydata.getList()
      }
    },
  },
};
</script>

<style scoped>
</style>