<template>
  <div class="alldata">
    <div class="topUsers">
      <div>
        <el-input v-model="conlistParams.keywords" placeholder="请输入名称关键字"></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="datalist"
      style="width: 100%"
    >
      <el-table-column prop="name" label="企业名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="tel" label="联系方式" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="register_time" label="注册时间" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="!scope.row.status" style="color: #FF6F7A;">未沟通</span>
          <span v-if="scope.row.status == 1" style="color: #0F38FF;">沟通中</span>
          <!-- <span v-if="scope.row.status == 2">添加到意向</span>
          <span v-if="scope.row.status == 3">添加到合作</span>-->
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button class="edit" size="mini" @click="com_cancle(scope.row.id)">取消沟通</el-button>
          <el-button class="edit" size="mini" @click="add_join(scope.row.id)">添加到合作</el-button>
          <el-button class="edit" size="mini" @click="add_intent(scope.row.id)">添加到意向</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block" v-if="datalist.total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="datalistParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="datalistParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="datalist.total"
      ></el-pagination>
    </div>

    <el-dialog top="20%" :visible.sync="dialogJoin" width="20%">
      <span>{{ joinText}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addSure()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog top="20%" :visible.sync="dialogSucc" width="20%">
      <img
        style="width: 60px;display: block;margin: 12px auto;"
        src="../../assets/images/success_icon.png"
        alt
      />
      <span>{{ succText}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addSure = dialogSucc = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: '',
      joinText: '',//确定添加文本
      succText: '',//添加成功文本
      dialogJoin: false,
      dialogSucc: false,
      loading: false,
      datalist: [{
        title: 1
      }],
      conlistParams: {
        page: 1,
        limit: 10,
        keywords: "",
        status: 1,
      },
    };
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/company/custom_pool', { params: { ...this.conlistParams } }).then((res) => {
        this.datalist = res.data.data
      })
    },
    handleSizeChange (val) {
      this.getList(this.conlistParams.limit = val)
    },
    handleCurrentChange (val) {
      this.getList(this.conlistParams.page = val)
    },
    handleSearch () {
      this.getList()
    },
    com_cancle (id) {
      this.axios.post('/api/company/cancel_connect', { id: id }).then((res) => {
        this.$message.success('已取消沟通')
        this.getList()
      })
    },
    add_join (id) {
      this.dialogJoin = true
      this.joinText = '确定将企业添加到合作客户！'
      this.id = id
    },
    add_intent (id) {
      this.dialogJoin = true
      this.joinText = '确定将企业添加到意向客户！'
      this.id = id
    },
    addSure () {
      if (this.joinText.includes('合作')) {
        this.axios.post('/api/company/add_custom_company', {
          id: this.id,
          status: 3
        }).then(() => {
          this.getList()
          this.succText = '您已成功将企业添加到合作客户！'
          this.dialogSucc = true
          this.dialogJoin = false
        })
      } else {
        this.axios.post('/api/company/add_custom_company', {
          id: this.id,
          status: 2
        }).then(() => {
          this.getList()
          this.succText = '您已成功将企业添加到意向客户！'
          this.dialogSucc = true
          this.dialogJoin = false
        })
      }
    },
  },
};
</script>

<style scoped>
.topUsers {
  margin-top: 22px !important;
}
div/deep/.el-dialog__body {
  text-align: center;
  margin: 40px 0 20px 0;
}
</style>